import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Product } from '../../pim-graphql.model';
import { PimGraphqlService } from '../../pim-graphql.service';
import {
  HeaderData,
  PathElement,
  ListEntry,
  ListEntryAttribute,
} from '../shared-view.model';
import { SharedViewService } from '../shared-view.service';
import { ProductTypeData } from './product-type.model';
import { PimRoutePipe } from 'src/app/modules/pipes/pim-route.pipe';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductTypeService {
  constructor(
    private pim: PimGraphqlService,
    private router: Router,
    private sharedService: SharedViewService,
    private routePipe: PimRoutePipe,
  ) {}

  getProductTypeData(id: string): Observable<ProductTypeData | undefined> {
    return this.pim.getProductTypeById(id).pipe(
      map((productType) => {
        if (!productType || productType.objectType.id !== 'OT_ProductType') {
          this.router.navigate(['/'], { skipLocationChange: true });
          return;
        }
        return this.setProductType(productType);
      }),
    );
  }

  private setProductType(productType: Product): ProductTypeData {
    return {
      headerData: this.getHeaderData(productType),
      sets: this.getSets(productType),
    };
  }

  private getHeaderData(productType: Product): HeaderData {
    const classification = productType.parent?.referencesByReferenceType?.find(
      (ref) => {
        return ref.referenceType.id === environment.pim.classificationRef;
      },
    )?.referenceEntries?.[0]?.target;

    const path: PathElement[] | undefined = classification?.path
      .slice(
        classification.path.findIndex((path) => {
          return path.id === environment.pim.topClassification;
        }),
      )
      .map((x) => {
        return {
          title: x.title,
          link: this.routePipe.transform(x.id, x.objectType.id),
        };
      });

    if (classification) {
      path?.push({
        title: classification?.title,
        link: this.routePipe.transform(
          classification.id,
          classification.objectType.id,
        ),
      });
    }
    if (productType.path) {
      path?.push({
        title: productType.path?.[productType.path.length - 1].title,
        link: this.routePipe.transform(
          productType.path?.[productType.path.length - 1].id,
          productType.path?.[productType.path.length - 1].objectType.id,
        ),
      });
    }
    path?.push({
      title: productType.title,
      link: this.routePipe.transform(productType.id, productType.objectType.id),
    });

    const icon = productType.referencesByReferenceType
      ?.find((ref) => ref.referenceType.id === 'REF_Branding_Icon')
      ?.referenceEntries.find((img) =>
        img.target.values
          ?.find((val) => val.attribute.id === 'AT_IconType')
          ?.values.some((val) => val.valueId === 'white BG vitorange'),
      )
      ?.target.values?.find(
        (val) => val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_400',
      )?.simpleValue;

    let backLink;
    if (path && path.length > 1) {
      backLink = path[path.length - 2].link;
    }

    return {
      title: productType.title,
      backLink: backLink,
      breadcrumbs: path,
      iconUrl: icon,
    };
  }

  private getSets(productType: Product): ListEntry[] {
    const validAttributes = [
      'AT_MAKTX',
      'AT_Shoptext_calc',
      'AT_SAP_E_EFFIZIENZKLASSE_H',
      'AT_SAP_E_EFFIZIENZKLASSE_W',
      'AT_SAP_E_EFFIZIENZKLASSE_S',
      'AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_1',
      'AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_095',
      'AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_085',
      'AT_SAP_E_SPEZ_ENERGIEVERBRAUCHK_D_065',
    ];
    const displayableValues: string[] = [];
    const rawSets = productType.children?.pageElements;

    validAttributes.forEach((validAttr) => {
      for (let i = 0; i < rawSets.length; i++) {
        if (
          rawSets[i].values?.find((val) => val.attribute.id === validAttr)
            ?.simpleValue
        ) {
          displayableValues.push(validAttr);
          break;
        }
      }
    });

    return rawSets?.reduce<ListEntry[]>((sets, set) => {
      const attribute = displayableValues.reduce<ListEntryAttribute[]>(
        (attributes, attr) => {
          const value = set.values?.find((val) => val.attribute.id === attr);

          // Special logic for effiency ratings AT_SAP_E_EFFIZIENZKLASSE_...
          const metadataScale = value?.attribute.values?.find(
            (metaVal) => metaVal.attribute.id === 'AT_EfficiencyRange',
          )?.simpleValue;

          if (value?.simpleValue) {
            const displayValue = metadataScale
              ? `${value.simpleValue} (${metadataScale})`
              : value?.simpleValue;

            attributes.push({
              id: value.attribute.id,
              title: value.attribute.title,
              value: displayValue,
            });
          }
          return attributes;
        },
        [],
      );

      const imageRef = set.referencesByReferenceType?.find((ref) => {
        return (
          ref.referenceType.id === environment.pim.productType.productImageRef
        );
      });

      /*
        Searching for ImageAsset that has a AT_ProductImage_subtype
        value of "Freisteller (Frontale)" or "Freisteller (Ansicht)" (valueId: 110, )
        */
      let image;
      for (const subtypeId of environment.pim.productType
        .productImageSubtypeId) {
        image = imageRef?.referenceEntries.find((ref) => {
          return (
            ref.target.values?.find((val) => {
              return val.attribute.id === 'AT_ProductImage_subtype';
            })?.values[0]?.valueId === subtypeId
          );
        });
        if (image) break;
      }

      const imgLink = image?.target.values?.find(
        (val) => val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_400',
      )?.simpleValue;
      let setImage;
      if (!!imageRef && !!imageRef.referenceType && !!image && !!imgLink) {
        setImage = {
          imgLink: imgLink,
          title: imageRef.referenceType.title,
          id: imageRef.referenceType.id,
        };
      }

      if (this.sharedService.isValidForMarket(set)) {
        sets.push({
          id: set.id,
          title: set.title,
          link: this.routePipe.transform(set.id, set.objectType.id),
          attributes: attribute,
          image: setImage,
        });
      }
      return sets;
    }, []);
  }
}
