import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import {
  Classification,
  Product,
  ReferenceEntry,
} from '../../pim-graphql.model';
import { PimGraphqlService } from '../../pim-graphql.service';
import {
  CardData,
  HeaderData,
  ListEntry,
  ListEntryAttribute,
  PathElement,
} from '../shared-view.model';
import { SharedViewService } from '../shared-view.service';
import { ClassificationData } from './classification.model';
import { PimRoutePipe } from 'src/app/modules/pipes/pim-route.pipe';
import { LocaleService } from 'src/app/services/locale.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClassificationService {
  private validDescriptionAttributes = [
    'AT_Efficiency_Heating',
    'AT_Efficiency_WarmWater',
    'AT_Efficiency_Storage',
    'AT_SEV_D_065',
    'AT_SEV_D_085',
    'AT_SEV_D_095',
    'AT_SEV_D_1',
  ];

  private validMasterDescriptionAttributes = [
    'AT_Efficiency_Heating_global',
    'AT_Efficiency_WarmWater_global',
    'AT_Efficiency_Storage_global',
    'AT_SEV_D_065_global',
    'AT_SEV_D_085_global',
    'AT_SEV_D_095_global',
    'AT_SEV_D_1_global',
  ];

  constructor(
    private pim: PimGraphqlService,
    private locales: LocaleService,
    private sharedService: SharedViewService,
    private router: Router,
    private routePipe: PimRoutePipe,
  ) {}

  getClassificationData(
    id: string,
  ): Observable<ClassificationData | undefined> {
    return this.pim.getClassificationById(id).pipe(
      map((classification) => {
        if (
          !classification ||
          classification.objectType.id !== 'App_Structure'
        ) {
          this.router.navigate(['/'], { skipLocationChange: true });
          return;
        }
        return this.setClassification(classification);
      }),
    );
  }

  private setClassification(
    classification: Classification,
  ): ClassificationData {
    const groups = this.groupByObjectType(classification);

    return {
      topClassification:
        classification.id === environment.pim.topClassification,
      headerData: this.getHeaderData(classification),
      classifications: this.getClassifications(classification),
      products: this.getProducts(groups?.['OT_Product']),
      sets: this.getSets(groups?.['OT_Set']),
      packageTypes: this.getPackageTypes(groups?.['OT_PackageType']),
    };
  }

  private buildDescription(product: Product): string | undefined {
    const relevantAttributes = this.sharedService.isCurrentContextMaster()
      ? this.validMasterDescriptionAttributes
      : this.validDescriptionAttributes;
    const descriptionValues = product.values?.filter((val) =>
      relevantAttributes.includes(val.attribute.id),
    );

    let first = true;

    return (
      descriptionValues?.reduce<string>((desc, value) => {
        if (value.values && value.values.length > 0) {
          if (!first) desc += '\n';

          const joinedValues = value.values.map((val) => val.value).join(', ');

          desc += `${value.attribute.title}: ${joinedValues}`;

          const metadataScale = value?.attribute.values?.find(
            (metaVal) => metaVal.attribute.id === 'AT_EfficiencyRange',
          )?.simpleValue;
          if (metadataScale) desc += ` (${metadataScale})`;
          first = false;
        }
        return desc;
      }, '') ?? undefined
    );
  }

  private groupByObjectType(classification: Classification):
    | {
        [objectType: string]: ReferenceEntry[];
      }
    | undefined {
    const groups = classification.incomingReferencesByReferenceType
      ?.find((ref) => {
        return ref.referenceType.id === environment.pim.classificationRef;
      })
      ?.referenceEntries.pageElements.reduce<{
        [objectType: string]: ReferenceEntry[];
      }>((groups, ref) => {
        const prod = ref;
        if (groups[prod.source.objectType.id]) {
          groups[prod.source.objectType.id].push(prod);
        } else {
          groups[prod.source.objectType.id] = [prod];
        }
        return groups;
      }, {});

    for (const k in groups) {
      this.sharedService.sortByMetadataAttribute('AT_Position', groups[k]);
    }
    return groups;
  }

  private getClassifications(
    classification: Classification,
  ): CardData[] | undefined {
    return classification.children?.pageElements.reduce<CardData[]>(
      (children, c) => {
        if (this.sharedService.isValidMarket(c)) {
          children.push({
            title: c.title,
            link: this.routePipe.transform(c.id, c.objectType.id),
          });
        }
        return children;
      },
      [],
    );
  }

  private getProducts(products?: ReferenceEntry[]): CardData[] | undefined {
    return products?.reduce<CardData[]>((entries, ref) => {
      const product = ref.source;
      const salesOrg = product.values?.find(
        (val) => val.attribute.id === 'AT_SalesOrg_multi',
      );
      if (
        (salesOrg?.values.some(
          (val) => val.value === this.locales.getCurrentLocale().salesOrg,
        ) ||
          this.locales.getCurrentLocale().salesOrg.length === 0) &&
        this.sharedService.isValidForMarket(product)
      ) {
        const primaryImage = product.referencesByReferenceType?.find(
          (ref) => ref.referenceType.id === 'PrimaryProductImage',
        )?.referenceEntries[0]?.target;

        entries.push({
          title: product.title,
          link: this.routePipe.transform(product.id, product.objectType.id),
          imgLink: primaryImage?.values?.find(
            (val) =>
              val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_1200',
          )?.simpleValue,
          description: this.buildDescription(product),
        });
      }
      return entries;
    }, []);
  }

  private getSets(sets?: ReferenceEntry[]): ListEntry[] | undefined {
    return sets?.reduce<ListEntry[]>((entries, ref) => {
      const set = ref.source;
      const salesOrg = set.values?.find(
        (val) => val.attribute.id === 'AT_SalesOrg_multi',
      );
      if (
        (salesOrg?.values.some(
          (val) => val.value === this.locales.getCurrentLocale().salesOrg,
        ) ||
          this.locales.getCurrentLocale().salesOrg.length === 0) &&
        this.sharedService.isValidForMarket(set)
      ) {
        const attributes: ListEntryAttribute[] = [];
        set.values
          ?.map((val) => {
            return {
              id: val.attribute.id,
              title: val.attribute.title,
              value: val?.values[0]?.value,
            };
          })
          .forEach((attr) => {
            if (
              attr.id != 'AT_SalesOrg_multi' &&
              !this.validDescriptionAttributes.includes(attr.id)
            ) {
              attributes.push(attr);
            }
          });

        //PrimaryProductImage
        const primaryImageRef = set.referencesByReferenceType?.find(
          (ref) => ref.referenceType.id === 'PrimaryProductImage',
        );
        const primaryImage = primaryImageRef?.referenceEntries[0]?.target;
        const imageLink = primaryImage?.values?.find(
          (val) => val.attribute.id === 'AT_AssetPush_Conversion_URL_PNG_400',
        )?.simpleValue;

        let image;
        if (primaryImage && primaryImageRef && imageLink) {
          image = {
            id: primaryImage.id,
            title: primaryImageRef.referenceType.title,
            imgLink: imageLink,
          };
        }

        entries.push({
          id: set.id,
          title: set.title,
          link: this.routePipe.transform(set.id, set.objectType.id),
          attributes: attributes,
          image: image,
        });
      }
      return entries;
    }, []);
  }

  private getPackageTypes(
    packageTypes?: ReferenceEntry[],
  ): CardData[] | undefined {
    return packageTypes?.reduce<CardData[]>((entries, ref) => {
      const packageType = ref.source;
      if (this.sharedService.isValidForMarket(packageType)) {
        entries.push({
          title: packageType.title,
          link: this.routePipe.transform(
            packageType.id,
            packageType.objectType.id,
          ),
          description: this.buildDescription(packageType),
        });
      }
      return entries;
    }, []);
  }

  private getHeaderData(classification: Classification): HeaderData {
    const path: PathElement[] | undefined = classification?.path
      .slice(
        classification.path.findIndex((path) => {
          return path.id === environment.pim.topClassification;
        }),
      )
      .map((x) => {
        return {
          title: x.title,
          link: this.routePipe.transform(x.id, x.objectType.id),
        };
      });

    path?.push({
      title: classification?.title,
      link: this.routePipe.transform(
        classification.id,
        classification.objectType.id,
      ),
    });

    let backLink;
    if (path && path.length > 1) {
      backLink = path[path.length - 2].link;
    }

    return {
      title: classification.title,
      backLink: backLink,
      breadcrumbs: path,
    };
  }
}
